<template>
  <div class="w-full h-auto">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <header v-if="step === 1" class="
      mb-4
      flex flex-col-reverse
      items-center
      justify-center
      lg:flex-row lg:justify-between
      gap-3
    ">
      <div class="w-full md:max-w-sm grid grid-cols-1 lg:grid-cols-2 gap-4" v-if="idCompany === 1">
        <a-button type="primary" shape="round" :block="true" class="w-64" @click="changeStep(2)">
          <span class="flex items-start justify-start font-sans text-xs">
            <a-icon type="plus" :style="{ fontSize: '16px' }" class="mr-2" />
            <b>Nuevo informe anual</b>
          </span>
        </a-button>
      </div>
      <div class="w-full sm:max-w-sm">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="
            flex
            absolute
            inset-y-0
            left-0
            items-center
            pl-3
            pointer-events-none
          ">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"></path>
            </svg>
          </div>
          <a-input-search placeholder="Buscar un usuario en particular" class="rounded-full" style="width: 100%"
            v-model="filterSearch" />
        </div>
      </div>
    </header>
    <div v-if="step === 1"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 search-complaints">
      <a-table :columns="columns" :data-source="dataRows" :pagination="false" :scroll="{ x: '1220px' }">
        <div slot="fecha_reporte" class="title-size font-bold text-gray-500 text-center ">
          <p>Fecha de reporte</p>
        </div>
        <div slot="entidad_vigilada" class="title-size font-bold text-gray-500">
          Entidad vigilada
        </div>
        <div slot="responsable" class="title-size font-bold text-gray-500">
          Responsable
        </div>
        <div slot="tiempo" class="flex flex-row justify-items-center title-size font-bold text-gray-500">
          Tiempo
        </div>
        <div slot="tiempo" slot-scope="text, record" class="flex flex-row">
          <p class="w-full rounded-full text-center"
            :class="record.tiempo == 'Óptimo' ? 'text-green-500 font-bold' : 'text-red-500 font-bold'">
            {{ record.tiempo }}
          </p>

        </div>
        <div slot="estado" class="title-size font-bold text-gray-500">
          Estado
        </div>
        <div slot="status" slot-scope="text, record" class="flex flex-row">
          <p class="w-full rounded-full text-center"
            :class="record.estado == 'Borrador' ? 'text-blue-600 bg-blue-200' : 'text-green-600 bg-green-100'">
            {{ record.estado }}
          </p>

        </div>
        <div slot="actions" class="title-size font-bold text-gray-500">
          Acciones
        </div>
        <div slot="actions" slot-scope="text, record" class="flex flex-row justify-items-center">
          <button class="flex justify-center text-xs mx-auto text-blue h-min" @click="changeStep(3, record)">
            <a-icon type="eye" theme="twoTone" two-tone-color="#3366cc" :style="{
      fontSize: '20px',
    }" />
          </button>
          <button v-if="idCompany === 1" class="flex justify-center text-xs mx-auto text-blue h-min"
            @click="changeStep(4, record)">
            <a-icon type="edit" theme="twoTone" two-tone-color="#3366cc" :style="{
      fontSize: '20px',
    }" />
          </button>
        </div>
      </a-table>
      <div class="p-4 flex justify-end">
        <a-pagination v-model="page" :page-size="pageSize" :page-size-options="pageSizeOptions" :total="count"
          show-size-changer size="large" />
      </div>
    </div>

    <FloatingButton v-if="step === 2 || step === 4" label="GUARDAR COMO BORRADOR" success="true"
      @saveDraft="saveDraftBtn" />

    <DynamicForm v-if="step === 2 || step === 4" @methodSubmit="callApi" @saveDraft="saveDraft" @addAnexo="addAnexo"
      @saveDraftBtn="saveDraftBtn" @methodCancel="changeStep" :urlform="'annual-report'" :isReport="true"
      :initForm="step === 2 ? null : step === 4 ? getDetail : null" :disableBtn="false" class="dynamic" />

    <DetailDesignInformDCF v-if="step === 3" @methodCancel="changeStep" :getDetail="getDetail" />

  </div>
</template>

<script>
// #004884
const columns = [
  {
    slots: { title: "fecha_reporte" },
    dataIndex: "fecha_reporte",
    key: "fecha_reporte",
    width: 80,
  },
  {
    slots: { title: "entidad_vigilada" },
    dataIndex: "entidad_vigilada",
    key: "entidad_vigilada",
    width: 80,
  },
  {
    slots: { title: "responsable" },
    dataIndex: "responsable",
    key: "responsable",
    width: 80,
  },
  {
    slots: { title: "tiempo" },
    dataIndex: "tiempo",
    key: "tiempo",
    width: 60,
    ellipsis: true,
    scopedSlots: { customRender: 'tiempo' },
  },
  {
    slots: { title: "estado" },
    dataIndex: "estado",
    key: "estado",
    width: 60,
    ellipsis: true,
    scopedSlots: { customRender: 'status' },
  },
  {
    slots: { title: "actions" },
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    width: 40,
  },
];

import DynamicForm from "@/components/DynamicForm/";
import DetailDesignInformDCF from "../../../../components/DetailDesignInformDCF/index.vue"
import FloatingButton from "../../../../components/UI/FloatingButton.vue";

export default {
  components: { DynamicForm, FloatingButton, DetailDesignInformDCF },
  data() {
    return {
      getDetail: {},
      idCompany: 0,
      step: 1,
      loading: false,
      showTab: false,
      count: 0,
      filterSearch: "",
      page: 1,
      pageSize: 100,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      columns: columns,
      dataRows: []
    };
  },
  created() {

    this.getReports();
    this.getIdCompany();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.getData();
    },
  },
  methods: {
    async getIdCompany() {
      this.idCompany = await this.$store.state.session?.user?.id_company?.id;
    },
    async getLocal() {
      const id = localStorage.getItem("DynamicFormID");
      const jsonLocal = localStorage.getItem("DynamicForm");

      let data = {}

      if (id) {
        try {
          const parsedData = id;

          if (parsedData) {

            data['id'] = parsedData;
          }
        } catch (error) {
          data['id'] = null;
        }
      }


      if (jsonLocal) {
        try {
          const parsedData = JSON.parse(jsonLocal);

          if (typeof parsedData === "object") {

            data['json'] = parsedData;
          }
        } catch (error) {
          data['json'] = null;
        }
      }

      return data;
    },
    async getReports() {
      this.loading = true;
      let { error, data } = await this.$api.getReportsV2(`?page=${this.page}&page_size=${this.pageSize}`);

      if (error) {
        this.loading = false;
        return;
      }

      this.totalPages = data.pages;
      this.dataRows = data.data.map((row) => {
        return {
          key: row.document_id,
          fecha_reporte: row.fecha_reporte,
          entidad_vigilada: row.entidad_vigilada,
          responsable: row.responsable,
          tiempo: row.tiempo,
          estado: row.estado,
          actions: ''
        };
      });

      this.loading = false;
    },
    async getReport(DocId, step) {
      this.loading = true;

      let { data, error } = await this.$api.getReportsV2(`?id=${DocId}`, DocId);

      if (error) {
        this.loading = false;
        return;
      }

      if (step === 4) {
        localStorage.setItem(
          "DynamicFormID",
          DocId,
        );
      }

      this.getDetail = data[DocId]

      this.loading = false;

    },
    changeShowTab() {
      this.showTab = !this.showTab;
    },
    async changeStep(step = 1, record = "") {

      if (record != "") {
        await this.getReport(record.key, step);
      }

      this.step = step;

      if (step === 1) {
        await this.getReports()
      }

    },
    async callApi(json) {
      this.loading = true;

      const { data } = await this.$api.createAnualReport(json);

      localStorage.setItem(
        "DynamicFormID",
        data['document_id'],
      );

      this.loading = false;
    },
    async saveDraft() {
      this.loading = true;

      const { id, json } = await this.getLocal();

      await this.$api.updateAnualReport(json, id);


      this.loading = false;
    },
    async saveDraftBtn() {
      const { id, json } = await this.getLocal();

      if (id != null && json != null) {

        await this.saveDraft();

        this.$notification.success({
          message: "Notificación",
          description: "Guardado como borrador de forma exitosa",
        });

      } else {
        this.$notification.error({
          message: "Notificación",
          description: "No se pudo guardar borrador",
        });
      }

    },
    async addAnexo(dataAnexo) {
      const { id } = await this.getLocal();
      dataAnexo['id'] = id;

      await this.$api.createAnexo(dataAnexo);
    }
  },
};
</script>

<style scoped>
button {
  border-radius: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.p-10 {
  padding: 10px;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.btn-large {
  min-width: 300px;
}

.btn-small {
  min-width: 90px;
  padding: 3px;
}

.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
  width: 40px;
  height: 40px;
}

.btn-square-small {
  width: 26px;
  height: 26px;
}

.btn-gray {
  background: #e7e7e7;
  color: black;
}

.btn-primary {
  background: #004884;
  color: white;
}

.btn-warning {
  background: #f8b446;
  color: white;
}

.btn-danger {
  background: #ea4a76;
  color: white;
}

.btn-success {
  background: #309d7e;
  color: white;
}

.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}

.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}

.btn-primary-light {
  background: #edf3ff;
  color: #004884;
}

.btn-success-light {
  background: #e5fbf5;
  color: #309d7e;
}

.no-border {
  border: 0px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}

.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.search>input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}

.search>button {
  border-radius: 10px;
}

.table {
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}

.tableHeader {
  font-weight: bold;
}

.table>div {
  flex-wrap: wrap;
}

.table>div>div {
  border-bottom: 1px solid black;
  padding: 10px 1px;
}

.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}

.progressbar>div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #309d7e;
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}
</style>
