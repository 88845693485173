<template>
  <div>

    <DynamicForm :urlform="'annual-report'" :step=this.view v-if="this.view !== 0" :disableBtn="true"
      @methodCancelDetail="cancel" :initForm="this.data['steps'][this.view]" :isReport="true" />

    <div class="container items-start" v-if="this.view === 0">

      <Accordion :open="true" :disable="false" :header="'Detalles del informe anual'" class="mt-2">

        <DynamicForm :urlform="'annual-report'" :disableBtn="true" :initForm="this.data['steps'][0]" :isReport="true" />

        <div class="w-full flex flex-wrap place-content-around">

          <div class="w-1/4 mt-5 text-left flex flex-col ">
            <label class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80"><b>Estado del informe</b></label>
            <div class=" h-11 rounded text-center  flex items-center justify-center" style="background-color: #E5FBF5;">
              <p class="font-bold text-sm" style="color:  #309D7E;">{{ data.estado }}</p>
            </div>
          </div>

          <div class="w-1/4 mt-5 text-left flex flex-col align-middle">
            <label class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80"><b>Tiempo</b></label>
            <div class=" h-11 rounded text-center  flex items-center justify-center" style="background-color: #E5FBF5;">
              <p class="font-bold text-sm" style="color:  #309D7E;">{{ data.tiempo }}</p>
            </div>
          </div>
        </div>


      </Accordion>

      <p class="align-baseline font-bold text-lg mt-10 text-left" style="color: #3366CC;">Detalles de las funciones</p>


      <div class="container-dynamic-form w-full items-center mt-10">

        <p class="align-middle font-bold text-lg">1. De la función de atención y resolución de quejas </p>

        <button class="rounded ml-auto mr-4 btn-primary pt-3 pb-3 pr-5 pl-5 font-bold text-sm"
          style="background-color:#3366CC" v-on:click="changeView(1)">
          <span>Ver function</span>
        </button>

      </div>

      <div class="container-dynamic-form w-full items-center mt-10">

        <p class="align-middle font-bold text-lg">2. De la función de atención al consumidor financiero </p>
        <button class="rounded ml-auto mr-4 btn-primary pt-3 pb-3 pr-5 pl-5 font-bold text-sm"
          style="background-color:#3366CC" v-on:click="changeView(2)">
          <span>Ver function</span>
        </button>

      </div>
      <div class="container-dynamic-form w-full items-center mt-10">

        <p class="align-middle font-bold text-lg">3. De la función de conciliación </p>
        <button class="rounded ml-auto mr-4 btn-primary pt-3 pb-3 pr-5 pl-5 font-bold text-sm"
          style="background-color:#3366CC" v-on:click="changeView(3)">
          <span>Ver function</span>
        </button>

      </div>
      <div class="container-dynamic-form w-full items-center mt-10 mb-10">

        <p class="align-middle font-bold text-lg">4. Información adicional </p>
        <button class="rounded ml-auto mr-4 btn-primary pt-3 pb-3 pr-5 pl-5 font-bold text-sm"
          style="background-color:#3366CC" v-on:click="changeView(4)">
          <span>Ver function</span>
        </button>

      </div>
      <button class="btn btn-primary-outline ms-auto me-1 mt-10" v-on:click="cancel">
        VOLVER
      </button>


    </div>

  </div>
</template>

<style scoped>
.container-dynamic-form {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
}

.container {
  padding: 30px;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
}
</style>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import DynamicForm from "@/components/DynamicForm/";

export default {
  props: [
    "getDetail",],
  components: {
    Accordion,
    DynamicForm
  },
  data() {
    return {
      view: 0,
      data: {},
    }
  },
  created() {
    this.data = this.getDetail;
  },
  methods: {
    getLocalData() {
      const savedData = localStorage.getItem("DynamicForm");

      if (savedData) {
        try {
          const parsedData = JSON.parse(savedData);

          if (typeof parsedData === "object") {

            this.data = parsedData;
          }
        } catch (error) {

        }
      }
    },
    changeView(view = 0) {
      this.view = view;
    },
    cancel() {
      if (this.view == 0) {
        this.$emit("methodCancel", 1);
      } else {
        this.changeView(0)
      }
    },
  },
};
</script>